<template>
  <div :class="[loaderClass, 'relative overflow-hidden skeleton-loader']">
    <div class="shimmer absolute top-0 right-0 bottom-0 left-0" />
    <slot />
  </div>
</template>

<script setup lang="ts">
export interface Props {
  type?: string
}
const props = defineProps<Props>()
const loaderClass = props.type === 'circle' ? 'rounded-full' : 'rounded'
</script>

<style lang="css">
.skeleton-loader {
  background-color: rgba(1, 1, 1, 0.08);
}
.shimmer {
  transform: translateX(-100%);
  animation: shimmer 1.4s infinite;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>
